import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    currentGalleryFormValues: {},
    formInitiated: false
}

export const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    setCurrentFormValues: (state, action) => {
        state.currentGalleryFormValues = action.payload
        
    },
    setFormInitiated: (state, action) => {
        state.formInitiated = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setCurrentFormValues, setFormInitiated } = gallerySlice.actions
export const getCurrentFormValues = ({gallery}) => gallery?.currentGalleryFormValues;
export const getFormInitiated = ({gallery}) => gallery?.formInitiated;
export default gallerySlice.reducer